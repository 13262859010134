"use client";
import { useCallback, useEffect, useState } from "react";

import * as Sentry from "@sentry/nextjs";
import axios from "axios";
import { useSearchParams } from "next/navigation";
import { useLocale } from "next-intl";
import { v4 } from "uuid";

import { User, UserTraits } from "@efarmz/efarmz-domain-typescript";

import useAuth from "@/hooks/useAuth";

import AnalyticsContext from "@/contexts/AnalyticsContext";

import getCookieValue from "@/utils/client/getCookieValue";
import segment from "@/utils/client/segment";

type AnalyticsProviderProps = {
  children: React.ReactNode;
};

const setCookie = (name: string, value: string, duration: number) => {
  var expires = ``;
  if (duration) {
    var date = new Date();
    date.setTime(date.getTime() + duration);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ``}${expires}; path=/`;
};

let anonymousId: string | undefined = undefined;
let gaClientId: string | undefined = undefined;
let gaSessionId: string | undefined = undefined;
let gaSessionNumber: number | undefined = undefined;

if (
  typeof window !== `undefined` &&
  process?.env?.NEXT_PUBLIC_ENV_PROFILE === `production`
) {
  const gaCookie = getCookieValue(`_ga`);
  const gaEnhancedCokkie = getCookieValue(`_ga_WC9K1JFG8H`);
  const ajsCookie = getCookieValue(`ajs_anonymous_id`);

  if (gaCookie) {
    gaClientId = gaCookie.substring(6);
  }
  if (ajsCookie) {
    anonymousId = ajsCookie;
  }
  if (gaEnhancedCokkie) {
    const pattern = /GS\d\.\d\.(.+?)(?:;|$)/;
    const match = gaEnhancedCokkie.match(pattern);
    const parts = match?.[1].split(`.`);

    if (parts) {
      gaSessionId = parts.shift();
      gaSessionNumber = parseInt(parts.shift() || `0`);
    }
  }
}

const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const [hasBeenIdentified, setHasBeenIdentified] = useState(false);
  const [isSessionPersisted, setIsSessionPersisted] = useState(false);
  const searchParams = useSearchParams(); // const intercom = useIntercom();
  const { user } = useAuth();
  const locale = useLocale();

  // This will run one time after the component mounts
  useEffect(() => {
    // callback function to call when event triggers
    const onPageLoad = () => {
      setTimeout(() => {
        segment.load(
          {
            writeKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY!,
            cdnURL: `${process.env.NEXT_PUBLIC_EFARMZ_URL}/segment/cdn`,
          },
          {
            integrations: {
              "Segment.io": {
                // POST https://MY-CUSTOM-API-PROXY.com/v1/t --> proxies to
                // https://api.segment.io/v1/t
                apiHost: `${process.env.NEXT_PUBLIC_EFARMZ_URL!.replace(
                  `https://`,
                  ``
                )}/segment/api/v1`,
                protocol: `https`, // optional,
                deliveryStrategy: {
                  strategy: `batching`,
                  config: {
                    size: 10,
                    timeout: 5000,
                  },
                },
              },
            },
          }
        );
      }, 500);
      // do something else
    };

    window.addEventListener(`load`, onPageLoad, false);
    // Remove the event listener when component unmounts
    return () => window.removeEventListener(`load`, onPageLoad);
  }, []);

  const persistSession = useCallback(async (email: string) => {
    await axios.post(`/api/session`, {
      email,
    });
  }, []);

  useEffect(() => {
    if (searchParams?.has(`gclid`)) {
      setCookie(
        `efgaclkid`,
        searchParams.get(`gclid`) as string,
        30 * 24 * 60 * 60 * 1000
      );
    }

    if (!isSessionPersisted && user?.email) {
      if (!getCookieValue(`efsessid`)) {
        setCookie(`efsessid`, v4(), 6 * 3600 * 1000);
      }
      persistSession(user.email);
      setIsSessionPersisted(true);
    }
  }, [isSessionPersisted, persistSession, searchParams, user]);

  const identify = useCallback(
    (user: User & UserTraits) => {
      if (
        process?.env?.NEXT_PUBLIC_ENV_PROFILE === `production` &&
        user?.id &&
        user?.name
      ) {
        const firstName = user!.name!.split(` `).slice(0, -1).join(` `);
        const lastName = user!.name!.split(` `).slice(-1).join(` `);

        const traits: any = {
          email: user.email,
          firstName,
          lastName,
          name: user.name,
          locale,
          createdAt: user.createdAt,
        };
        if (user?.lastDeliveryWindow?.address) {
          traits.address = {
            street: user?.lastDeliveryWindow?.address?.line1,
            postalCode: user?.lastDeliveryWindow?.address?.zipcode,
            city: user?.lastDeliveryWindow?.address?.city,
            country: user?.lastDeliveryWindow?.address?.countryIso2,
          };
        }

        if (user?.lastDeliveryWindow?.address?.phone) {
          traits.phone = user.lastDeliveryWindow.address.phone;
        }

        const {
          firstOrderedAt,
          lastOrderedAt,
          registeredAt,
          subscribedAt,
          firstSubscriptionDeliveredAt,
          lastSubscriptionDeliveredAt,
          hasOrderedMeals,
          hasMealSubscription,
          hasActiveSubscription,
          hasOrderedMeat,
          hasOrderedAlcohol,
          hasOrderedBabyProduct,
          averageBasket,
          averageOneshotOrderPerMonth,
          averageSubscriptionOrderPerMonth,
          orderCount,
        } = user;
        segment?.identify(
          user!.id!.toString()!,
          {
            ...traits,
            firstOrderedAt,
            lastOrderedAt,
            registeredAt,
            subscribedAt,
            firstSubscriptionDeliveredAt,
            lastSubscriptionDeliveredAt,
            hasOrderedMeals,
            hasMealSubscription,
            hasActiveSubscription,
            hasOrderedMeat,
            hasOrderedAlcohol,
            hasOrderedBabyProduct,
            averageBasket,
            averageOneshotOrderPerMonth,
            averageSubscriptionOrderPerMonth,
            orderCount,
          },
          {
            context: {
              traits: {
                ...traits,
                gaClientId,
              },
            },
          }
        );
      }
    },
    [locale]
  );

  useEffect(() => {
    if (user && !hasBeenIdentified) {
      if (process?.env?.NEXT_PUBLIC_ENV_PROFILE === `production`) {
        const fetchTraits = async () => {
          const data = await axios
            .get(`/search/users-fr/_doc/${user.id}`, {
              headers: {
                Authorization: `ApiKey ${process.env
                  .NEXT_PUBLIC_SEARCH_API_KEY!}`,
              },
            })
            .then((response: any) => {
              return response?.data?._source;
            })
            .catch((e) => {
              return null;
            });
          return data;
        };
        fetchTraits().then((traits: any) => {
          if (traits) {
            try {
              const cookieYesValue = getCookieValue(`cookieyes-consent`);
              if (cookieYesValue) {
                const sectionSplit = cookieYesValue.split(`,`);

                if (sectionSplit && sectionSplit?.length > 0) {
                  const cookieConsent: any = {};

                  sectionSplit.forEach((item) => {
                    const itemSplit = item.split(`:`);
                    if (itemSplit.length === 2) {
                      cookieConsent[itemSplit[0]] = itemSplit[1];
                    }
                  });

                  if (cookieConsent?.analytics === `yes`) {
                    identify(traits);
                  }
                }
              }

              /*
            intercom.update({
              name: user.name!,
              email: user.email!,
              userId: user.email!,
            });*/

              Sentry.setUser({
                id: user.id,
                email: user.email,
                username: user.name,
              });
            } catch (e) {}
          }
        });
      }
      setHasBeenIdentified(true);
    }
  }, [hasBeenIdentified, identify, user]);

  return (
    <AnalyticsContext.Provider
      value={{
        sessionId: gaSessionId || getCookieValue(`efsessid`) || undefined,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
