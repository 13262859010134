import { addWeeks, getDay, getHours, getWeek, getYear } from "date-fns";

const getMealCurrentWeeks = (date?: Date) => {
  const currentDay = getDay(new Date());
  const currentHour = getHours(new Date());
  const calculatedDate =
    date ||
    (currentDay > 3 || (currentDay === 3 && currentHour >= 8)
      ? addWeeks(new Date(), 1)
      : new Date());

  return [
    calculatedDate,
    addWeeks(calculatedDate, 1),
    addWeeks(calculatedDate, 2),
  ].map(
    (date) =>
      `${getYear(date)}${getWeek(date).toLocaleString(`en-US`, {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}`
  );
};

export default getMealCurrentWeeks;
